import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import 'react-quill/dist/quill.snow.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux'
import store from '../src/redux/store';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { GoogleOAuthProvider } from "@react-oauth/google"
import { createRoot } from 'react-dom/client';

const root = createRoot(document.getElementById('root'));
root.render(
  <GoogleOAuthProvider clientId='271629988773-jaap9rfp0ci5ahh8ifeclut982pfj3eg.apps.googleusercontent.com'>
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
    <ToastContainer theme="colored" />
  </React.StrictMode>
  </GoogleOAuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();